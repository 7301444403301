import { template as template_5678265b8f4046fe8d91f94dd7c9e5e2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_5678265b8f4046fe8d91f94dd7c9e5e2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
