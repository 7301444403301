import { template as template_23dcaaeac95c40eb92835232b9dddf97 } from "@ember/template-compiler";
const FKText = template_23dcaaeac95c40eb92835232b9dddf97(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
